@import "../../global.scss";

.intro {
  background-color: black;
  display: flex;

  @include mobile {
    flex-direction: column;
    align-items: center;
  }
  @include ipad {
    flex-direction: column;
    align-items: center;
  }

  .left {
    flex: 0.5;
    overflow: hidden;
    justify-content: center;
    display: flex;
    align-items: center;

    @include ipad {
      background-color: white;
      width: 100vw;
      align-items: flex-end;
      background-color: black;
    }

    .imgContainer {
      width: 700px;
      height: 700px;
      background-color: white;

      border-radius: 50%;
      display: flex;
      align-items: flex-end;
      justify-content: flex-start;

      @include ipad {
        width: 100vw;
        height: 65vh;
        position: relative;
        top: 10%;
      }
      @include ipad {
        position: relative;
        justify-content: center;
        align-items: center;
        display: flex;
        width: auto;
        height: 110%;
        overflow-y: auto;
        padding-left: 0;
        border-radius: 0;
        transform: rotateY(0deg);
        border-radius: 250px;
        content: url(../../../public/assets/headshot.webp);
      }

      .headshot {
        align-items: center;
        justify-content: flex-start;
        display: flex;
        transform: rotateY(180deg);
        height: 85%;
        width: auto;
      }
    }
  }
  .right {
    flex: 0.5;
    position: relative;

    .wrapper {
      width: 100%;
      height: 100%;
      padding-left: 50px;
      display: flex;
      flex-direction: column;
      justify-content: center;
      color: whitesmoke;

      @include mobile {
        padding-left: 0;
        align-items: center;
      }

      h1 {
        font-size: 80px;
        margin: 10px 0;

        @include mobile {
          font-size: 50px;
        }
      }
      h2 {
        font-size: 30px;
      }
      h3 {
        font-size: 35px;

        @include mobile {
          font-size: 20px;
        }

        span {
          font-size: inherit;
          color: lightgreen;
        }

        .ityped-cursor {
          animation: blink 3s infinite;
        }

        @keyframes blink {
          50% {
            opacity: 1;
          }
          100% {
            opacity: 0;
          }
        }
      }
    }
    a {
      position: absolute;
      bottom: 10px;
      left: 40%;
      svg {
        color: lightgreen;
        height: 80px;
        width: 300px;
        @include mobile {
          width: 50px;
        }
        animation: arrowBlink 2s infinite;
      }

      @keyframes arrowBlink {
        100% {
          opacity: 0.25;
        }
      }
    }
  }
}
