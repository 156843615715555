@import "../../global.scss";

.testimonials {
  background-color: whitesmoke;
  display: flex;
  align-items: center;
  flex-direction: column;

  @include mobile {
    justify-content: space-around;
  }
  h1 {
    font-size: 50px;
    @include mobile {
      font-size: 20px;
    }
    @include ipad {
      padding-top: 25px;
    }
  }
  .container {
    width: 100%;
    height: 80%;
    display: flex;
    align-items: center;
    justify-content: center;
    @include mobile {
      flex-direction: column;
      height: 100%;
    }

    .card {
      cursor: default;
      a {
        text-decoration: none;
        color: inherit;
      }
      width: 250px;
      height: 70%;
      border-radius: 10px;
      box-shadow: 0px 0px 10px -4px black;
      display: flex;
      flex-direction: column;
      justify-content: space-around;
      padding: 20px;
      @include mobile {
        height: 180px;
        margin: 10px 0;
      }

      &.featured {
        width: 300px;
        height: 75%;
        margin: 0 30px;
        @include mobile {
          width: 250px;
          height: 180px;
          margin: 1px;
        }
      }
      &:hover {
        transform: scale(1.1);
        transition: all 1s ease;
      }
      .top {
        display: flex;
        align-items: center;
        justify-content: center;
        img {
          &.left,
          &.right {
            height: 25px;
          }
          &.user {
            height: 60px;
            width: 60px;
            border-radius: 50%;
            object-fit: cover;
            margin: 0 30px;
            @include mobile {
              width: 30px;
              height: 30px;
            }
          }
        }
        padding-bottom: 5px;
      }
      .middle {
        padding: 10px;
        background-color: #dfdfdf;
        border-radius: 10px;
        text-align: left;
        @include mobile {
          font-size: 9px;
          padding: 5px;
          text-align: center;
          overflow-y: hidden;
        }
      }
      .bottom {
        display: flex;
        align-items: center;
        flex-direction: column;
        justify-content: center;
        h3 {
          margin-bottom: 5px;
          @include mobile {
            font-size: 12px;
            text-align: center;
          }
        }
        h4 {
          color: gray;
          text-align: center;
          @include mobile {
            font-size: 12px;
            text-align: center;
          }
        }
      }
    }
  }
}
