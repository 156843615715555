@import "../../global.scss";

.navbar {
  background-color: whitesmoke;
  color: $mainColor;
  width: 100%;
  height: 70px;
  position: fixed;
  top: 0;
  z-index: 3;
  transition: all 1s ease;

  .wrapper {
    padding-left: 10px;

    display: flex;
    align-items: center;
    justify-content: space-between;

    .left {
      display: flex;
      align-items: center;
      #logo {
        width: 70px;
        height: 70px;
        flex-wrap: wrap;
        overflow-y: hidden;
        &:hover {
          color: lightgreen;
        }
      }
      .logo {
        .name {
          font-size: 25px;
          font-weight: 700;
        }
        font-size: 30px;
        font-weight: 700;
        text-decoration: none;
        color: inherit;
        margin-right: 15px;
      }

      .socialContainer {
        justify-content: center;
        padding-left: 20px;
        display: flex;
        align-items: center;
        width: 50px;
        color: red;
        @include mobile {
          padding-left: 10px;
          width: 35px;
        }

        .icon {
          align-items: center;
          font-size: 30px;
          margin-right: 20px;
          color: black;
          &:hover {
            font-size: 40px;
            color: lightgreen;
            transition: all 0.3s ease;
          }
        }
      }
    }
    .right {
      padding-right: 15px;
      padding-left: 15px;
      .hamburger {
        width: 32px;
        height: 25px;
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        cursor: pointer;

        span {
          width: 100%;
          height: 3px;
          background-color: $mainColor;
          transform-origin: left;
          transition: all 1s ease;
        }
      }
    }
  }

  &.active {
    background-color: $mainColor;
    color: white;
    .icon {
      filter: invert(100%);
      &:hover {
        filter: invert(0);
      }
    }
    .hamburger {
      span {
        &:first-child {
          background-color: white;
          transform: rotate(45deg);
        }
        &:nth-child(2) {
          opacity: 0;
        }
        &:last-child {
          background-color: white;
          transform: rotate(-45deg);
        }
      }
    }
  }
}
