@import "../../global.scss";

.works {
  background-color: black;
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;

  .arrow {
    height: 50px;
    position: absolute;
    cursor: pointer;

    @include mobile {
      display: none;
    }

    &.left {
      left: 100px;
      transform: rotateY(180deg);
    }

    &.right {
      right: 100px;
    }
  }

  .slider {
    height: 500px;
    display: flex;
    position: absolute;
    left: 0;
    transition: all 1s ease-out;

    @include mobile {
      height: 70vh;
      flex-direction: column;
      justify-content: center;
    }

    .container {
      width: 100vw;
      display: flex;
      align-items: center;
      justify-content: center;

      .item {
        width: 900px;
        height: 100%;
        background-color: white;
        border-radius: 20px;
        display: flex;
        align-items: center;
        justify-content: center;

        @include mobile {
          width: 80%;
          height: 100px;
          margin: 15px 0;
          border-radius: 20px;
        }

        .left {
          flex: 4;
          height: 100%;
          display: flex;
          align-items: center;
          justify-content: center;

          .leftContainer {
            width: 90%;
            height: 80%;
            display: flex;
            flex-direction: column;
            justify-content: space-around;
            align-items: center;

            .imgContainer {
              width: 40px;
              height: 40px;
              border-radius: 50%;
              background-color: rgb(245, 179, 155);
              display: flex;
              align-items: center;
              justify-content: center;

              @include mobile {
                width: 20px;
                height: 20px;
              }

              img {
                width: 25px;

                @include mobile {
                  width: 15px;
                }
              }
            }
            h2 {
              font-size: 20px;

              @include mobile {
                font-size: 11px;
                text-align: center;
              }
            }
            p {
              font-size: 12px;
              @include mobile {
                display: none;
              }
            }
            a {
              text-decoration: none;
              color: inherit;

              span {
                font-size: 10px;
                font-weight: 600;
                text-decoration: underline;
                cursor: pointer;
              }
            }
          }
        }
        .right {
          flex: 8;
          height: 100%;
          display: flex;
          align-items: center;
          justify-content: center;
          overflow: hidden;

          img {
            width: 400px;
            position: relative;
            left: 15px;
            bottom: 5px;
            border-radius: 40px;
            box-shadow: 5px 5px 20px 0px grey;
            transform: rotate(-15deg) scale(1.2) scaleY(1.3);
            @include mobile {
              width: 150px;
              transform: rotate(0deg) scale(1) scaleY(1.5);
              border-radius: 0;
            }
          }
        }
      }
    }
  }
}
