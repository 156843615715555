@import "../../global.scss";
.portfolio {
  background-color: whitesmoke;
  display: flex;
  flex-direction: column;
  align-items: center;

  h1 {
    padding-top: 25px;
    font-size: 50px;
    @include mobile {
      padding-top: 50px;
      font-size: 20px;
      overflow: scroll;
    }
  }
  ul {
    margin: 10px;
    padding: 0%;
    list-style: none;
    display: flex;

    @include mobile {
      margin: 10px 0;
      flex-wrap: wrap;
      justify-content: center;
      align-items: center;
      display: flex;
    }
  }

  .container {
    width: 60%;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-wrap: wrap;

    @include mobile {
      width: 100%;
    }

    .item {
      width: 245px;
      height: 175px;
      border-radius: 20px;
      border: 1px solid lightgreen;
      margin: 10px 20px;
      display: flex;
      align-items: center;
      justify-content: center;
      color: white;
      position: relative;
      transition: all 0.5s ease;
      cursor: pointer;

      @include mobile {
        width: 130px;
        height: 100px;
      }

      h3 {
        position: absolute;
        font-style: 20px;
      }
      img {
        width: 100%;
        height: 100%;
        object-fit: cover;
        z-index: 1;
      }
      &:hover {
        background-color: $mainColor;
        img {
          opacity: 0.2;
          z-index: 0;
        }
      }
    }
  }
}
