$mainColor: black;

$width: 768px;
$ipadWidth: 834px;

@mixin ipad {
  @media (max-width: #{$ipadWidth}) {
    @content;
  }
}

@mixin mobile {
  @media (max-width: #{$width}) {
    @content;
  }
}
